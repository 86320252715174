import {defineStore} from "pinia";
import {getLangs} from "../constants.js";
import {getActiveLanguage} from "laravel-vue-i18n";

export const useUser = defineStore('user', {
    state: () => {
        return {
            usersOnline: null,
            onlineChannel: null,
            lang: getLangs().find(item => item.code === getActiveLanguage()),
            theme: 'light',
        }
    },
    getters: {
        getUserStatusByIdOrStatus: (state) => {
            return (id, status) => {
                return Boolean(state.usersOnline?.[id]) || status;
            }
        },
    },
    actions: {
        toggleTheme() {
            if(this.theme === 'dark') this.theme = 'light';
            else this.theme = 'dark';
            localStorage.setItem('color-scheme', this.theme);
        }
    }
})
